<template>
  <div>
    <el-table class="mb-20" stripe border :data="list">
      <!--      <el-table-column align="center" prop="number" label="学号"/>-->
      <el-table-column align="center" prop="id" label="ID"/>
      <el-table-column align="center" prop="article_id" label="文章ID"/>
      <el-table-column align="center" prop="article_id" label="发布人">
        <template #default="{row, $index}">
          {{row.article.user.nickName}}(ID: {{row.article.user.id}})
        </template>
      </el-table-column>
      <el-table-column align="center" prop="accusation_title" label="帖子类型">
        <template #default="{row, $index}">
          {{articleTypeStr(row.article.sub_type)}}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="article.article_status.text" label="帖子状态"/>
      <el-table-column align="center" prop="accusation_title" label="举报类型"/>
      <el-table-column align="center" prop="remark" label="其他原因"/>
      <el-table-column align="center" label="操作" width="200">
        <template #default="{row, $index}">
          <!--          <el-button type="text" @click="showDetailBox">查看详情</el-button>-->
          <el-button type="text" @click="showDetailBox(row)">查看详情</el-button>
          <el-divider direction="vertical"></el-divider>
          <el-button type="text" @click="openDialog(row)">封禁</el-button>
<!--          <el-popconfirm title="是否确定封禁?" confirmButtonText="确定" cancelButtonText="取消" @confirm="openDialog(row)">-->
<!--            <template #reference>-->
<!--              <el-button type="text">封禁</el-button>-->
<!--            </template>-->
<!--          </el-popconfirm>-->
          <el-divider direction="vertical"></el-divider>
          <el-popconfirm title="是否忽略?" confirmButtonText="确定" cancelButtonText="取消" @confirm="examine(row, 10)">
            <template #reference>
              <el-button type="text">忽略</el-button>
            </template>
          </el-popconfirm>

        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="end">
      <el-col span="1">
        <el-pagination
          :page="params.page"
          :total="total"
          :page-sizes="params.per_page"
          :current-page="params.page"
          :page-size="10"
          @current-change="changePage"
          layout="prev, pager, next"
          background
        ></el-pagination>
      </el-col>
    </el-row>
    <el-dialog v-model="dialogTableVisible" title="封禁说明">
      <el-form :form="form">
        <el-form-item label="封禁说明" :label-width="formLabelWidth">
          <el-input
            v-model="form.reason"
            :rows="3"
            type="textarea"
            placeholder="填写封禁说明"
          />
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="cancal">取消</el-button>
        <el-button type="primary" @click="confirm"
        >确定</el-button
        >
      </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="detailBox"
      title="贴子详情"
      width="700px"
      top="5vh"
    >
      <el-descriptions border :column="1" v-if="detail && detail.sub_type == 'ArticleCarpool'">
        <el-descriptions-item label="出发地">{{detail.sub.start_place}}</el-descriptions-item>
        <el-descriptions-item label="目的地">{{detail.sub.end_place}}</el-descriptions-item>
        <el-descriptions-item label="出发时间">{{detail.sub.start_time}}</el-descriptions-item>
        <el-descriptions-item label="余座">{{detail.sub.surplus_seat}}</el-descriptions-item>
        <el-descriptions-item label="发布人">{{detail.user.nickName}}</el-descriptions-item>
        <el-descriptions-item label="发布人电话">{{detail.user.phone}}</el-descriptions-item>
        <el-descriptions-item label="发布人微信">{{detail.user.wechat}}</el-descriptions-item>
        <el-descriptions-item label="其他要求">{{detail.sub.remark}}</el-descriptions-item>
        <el-descriptions-item label="发布时间">{{detail.create_time}}</el-descriptions-item>
        <el-descriptions-item label="帖子状态">{{detail.article_status.text}}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions border :column="1" v-else-if="detail && detail.sub_type == 'ArticleTransfer'">
        <el-descriptions-item label="标题">{{detail.sub.goods_name}}</el-descriptions-item>
        <el-descriptions-item label="发布者">{{detail.user.nickName}}</el-descriptions-item>
        <el-descriptions-item label="发布者手机号">{{detail.user.phone}}</el-descriptions-item>
        <el-descriptions-item label="照片">
          <el-image
            v-for="(item, index) in detail.file"
            :key="index"
            style="width: 100px;height: 100px;margin-right: 10px;"
            :src="globalData.cos_domain + (item.thumbnail || item.url)"
            :preview-src-list="[globalData.cos_domain + (item.thumbnail || item.url)]"
          />
        </el-descriptions-item>
        <el-descriptions-item label="闲置分类">{{detail.sub.category_name}}</el-descriptions-item>
        <el-descriptions-item label="售价">{{detail.sub.price}}</el-descriptions-item>
        <el-descriptions-item label="发布时间">{{detail.create_time}}</el-descriptions-item>
        <el-descriptions-item label="上架状态">{{detail.sub.status.text}}</el-descriptions-item>
        <el-descriptions-item label="帖子状态">{{detail.article_status.text}}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions border :column="1" v-else-if="detail && detail.sub_type == 'ArticleLostAndFind'">
        <el-descriptions-item label="物品">{{detail.sub.title}}</el-descriptions-item>
        <el-descriptions-item label="照片">
          <el-image
            v-for="(item, index) in detail.file"
            :key="index"
            style="width: 100px;height: 100px;margin-right: 10px;"
            :src="globalData.cos_domain + (item.thumbnail || item.url)"
            :preview-src-list="[globalData.cos_domain + (item.thumbnail || item.url)]"
          />
        </el-descriptions-item>
        <el-descriptions-item label="发布人">{{detail.user.nickName}}</el-descriptions-item>
        <el-descriptions-item label="发布人电话">{{detail.user.phone}}</el-descriptions-item>
        <el-descriptions-item label="发布人微信">{{detail.user.wechat}}</el-descriptions-item>
        <el-descriptions-item label="类型">{{detail.sub.type.text}}</el-descriptions-item>
        <el-descriptions-item label="状态">{{detail.sub.status.text}}</el-descriptions-item>
        <el-descriptions-item label="发布时间">{{detail.create_time}}</el-descriptions-item>
        <el-descriptions-item label="帖子状态">{{detail.article_status.text}}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions border :column="1" v-else-if="detail && detail.sub_type == 'ArticleLegwork'">
        <!--        <el-descriptions-item label="需求">实验仪器</el-descriptions-item>-->
        <el-descriptions-item label="取件地址">{{detail.sub.take_address}}</el-descriptions-item>
        <el-descriptions-item label="送达地址">{{detail.sub.target_address}}</el-descriptions-item>
        <el-descriptions-item label="发布人">{{detail.user.nickName}}</el-descriptions-item>
        <el-descriptions-item label="发布人电话">{{detail.user.phone}}</el-descriptions-item>
        <el-descriptions-item label="接单人">{{detail.sub.receiver_id ? detail.sub.receiver.nickName : ''}}</el-descriptions-item>
        <el-descriptions-item label="接单人电话">{{detail.sub.receiver_id ? detail.receiver.phone : ''}}</el-descriptions-item>
        <el-descriptions-item label="跑腿费">{{detail.sub.price}}</el-descriptions-item>
        <el-descriptions-item label="状态">{{detail.sub.status.text}}</el-descriptions-item>
        <el-descriptions-item label="发布时间">{{detail.create_time}}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions border :column="1" v-else-if="detail && detail.sub_type == 'ArticleTalk'">
        <el-descriptions-item label="内容">{{detail.sub.content || detail.sub.title || detail.sub.goods_name}}</el-descriptions-item>
        <el-descriptions-item label="标签">{{detail.sub.topic_title}}</el-descriptions-item>
<!--        <el-descriptions-item label="内容">{{detail.sub.content}}</el-descriptions-item>-->
        <el-descriptions-item label="照片">
          <el-image
            v-for="(item, index) in detail.file"
            :key="index"
            style="width: 100px;height: 100px;margin-right: 10px;"
            :src="globalData.cos_domain + (item.thumbnail || item.url)"
            :preview-src-list="[globalData.cos_domain + (item.thumbnail || item.url)]"
          />
        </el-descriptions-item>
        <el-descriptions-item label="发布人">{{detail.user.nickName}}</el-descriptions-item>
        <el-descriptions-item label="发布时间">{{detail.create_time}}</el-descriptions-item>
        <el-descriptions-item label="帖子状态">{{detail.article_status.text}}</el-descriptions-item>
      </el-descriptions>
      <template #footer>
        <div>
          <el-button @click="hideDetailBox">关闭</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import VueCookies from "vue-cookies";
import { mapState } from 'vuex'
import {ElMessage} from "element-plus";
export default {
  name: 'ReportList',
  data() {
    return {
      params: {
        page: 1,
        per_page: 10,
        search: '',
      },
      total: 1,
      list: [],
      detailBox: false,
      formLabelWidth: '120px',
      dialogTableVisible: false,
      detail: null,
      form: {
        reason: ''
      },
    };
  },
  created() {
    this.getList(1)
  },
  computed: {
    ...mapState(['globalData'])
  },
  methods: {
    articleTypeStr(type) {
      switch (type) {
        case 'ArticleTalk':
          return '话题贴'
          break;
        case 'ArticleTransfer':
          return '二手闲置'
          break;
        case 'ArticleLostAndFind':
          return '失物招领'
          break;
        case 'ArticleCarpool':
          return '拼车'
          break;
        case 'ArticleLegwork':
          return '跑腿'
      }

    },
    showDetailBox(data) {
      console.log(data.article);
      this.detail = data.article;
      this.detailBox = true;
    },
    hideDetailBox() {
      this.detailBox = false;
    },
    getList(page) {
      let that = this;
      that.params.load = true
      this.$api.accusationArticle.page({
        page: page || this.params.page,
      }).then((res) => {
        // console.log(res);
        // console.log(that.$store.state);
        let list = res.data.data;
        list.forEach(item => {
          item.st_idcard_front = that.$store.state.globalData.cos_domain + item.st_idcard_front
        })
        that.list = list;
        that.total = res.data.total;
        that.params.per_page = res.data.per_page;
        that.params.page = (page || this.params.page);
      });
    },
    submit() {
      this.getList(1)
    },
    openDialog(data) {
      this.flagData = Object.assign({} ,data)
      this.switchDialog()
    },
    cancal() {
      this.form = {
        reason: '',
      }
      this.flagData = {};
      this.switchDialog()
    },
    confirm() {
      this.$api.accusationArticle.operate({
        id: this.flagData.id,
        status: 30,
        reason: this.form.reason
      }).then(res => {

        ElMessage({
          message: '操作成功',
          type: 'success',
        })
        this.getList()
        this.cancal()
      })
    },
    reset() {
      this.params.search = '';
      this.submit()
    },
    changePage(page) {
      this.getList(page)
    },
    switchDialog() {
      this.dialogTableVisible = !this.dialogTableVisible
    },
    examine(data, examine_status) {
      this.$api.accusationArticle.operate({
        id: data.id,
        status: examine_status,
      }).then(res => {
        ElMessage({
          message: '操作成功',
          type: 'success',
        })
        this.getList()
      })
    }
  },
};
</script>
