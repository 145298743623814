<template>
  <div>
    <el-row :gutter="20" style="margin-top:10px;">
      <el-col :span="8">
        <div class="grid-content bg-purple">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>个人中心</span> <el-button type="text" @click="switchDialog">修改密码</el-button>
            </div>
            <div class="name-role">
              <span class="sender">{{userInfo.username}}</span>
            </div>
            <div class="registe-info">
          <span class="registe-info">
            注册时间：
            <li class="fa fa-clock-o"></li>
             {{userInfo.create_time}}
          </span>
            </div>
            <el-divider></el-divider>
            <div class="personal-relation">
              <div class="relation-item">手机号:  <div style="float: right; padding-right:20px;">{{userInfo.phone}}</div></div>
            </div>
          </el-card>
        </div>
      </el-col>
      <el-col :span="16">
        <div class="grid-content bg-purple">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>基本资料</span>
            </div>
            <div>
              <el-form label-width="80px" v-model="dataFrom" size="small" label-position="right">
                <el-form-item label="手机号" prop="phone">
                  <el-input auto-complete="off" v-model="flagData.phone"></el-input>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button size="mini" type="primary" @click="submit">提交</el-button>
<!--                <el-button size="mini" type="warning" >关闭</el-button>-->
              </div>
            </div>
          </el-card>
        </div>
      </el-col>

    </el-row>
    <el-dialog v-model="dialogTableVisible" title="修改密码">
      <el-form :form="form">
        <el-form-item label="密码" :label-width="formLabelWidth">
          <el-input v-model="form.password" autocomplete="off" show-password></el-input>
        </el-form-item>
        <el-form-item label="确认密码" :label-width="formLabelWidth">
          <el-input v-model="form.password_confirm" autocomplete="off" show-password></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="cancal">取消</el-button>
        <el-button type="primary" @click="confirm"
        >确定</el-button
        >
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from "vuex";
import { ElMessage } from 'element-plus'

export default {
  name: 'UserDetail',
  data(){
    return{
      flagData: {},
      dialogTableVisible: false,
      formLabelWidth: '120px',
      form: {
        password: '',
        password_confirm: ''
      }
    }
  },
  watch: {
    '$store.state.userInfo': (e) => {
      // this.flagData = Object.assign({}, e)
    },
    'dialogTableVisible': (e) => {
      // console.log(e);
    }
  },
  computed: {
    ...mapState(['userInfo']),
  },
  created() {
    this.flagData = Object.assign({}, this.userInfo)
  },
  methods: {
    switchDialog() {
      this.dialogTableVisible = !this.dialogTableVisible;
    },
    submit() {
      this.$api.user.edit(this.flagData).then(res => {
        ElMessage({
          message: '修改成功',
          type: 'success',
        })
        this.$store.state.userInfo = Object.assign({}, this.flagData)
      })
    },
    cancal() {
      this.form = {
        password: '',
        password_confirm: ''
      }
      this.switchDialog()
    },
    confirm() {
      this.$api.user.edit({
        ...this.userInfo,
        ...this.form,
      }).then(res => {
        ElMessage({
          message: '修改成功',
          type: 'success',
        })
        this.cancal()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
//卡片样式
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both
}

.box-card {
  width: 100%;
}
//文本样式区
.name-role {
  font-size: 16px;
  padding: 5px;
  text-align:center;
}
.sender{
  text-align:center;
}
.registe-info{
  text-align: center;
  padding-top:10px;
}
.personal-relation {
  font-size: 16px;
  padding: 0px 5px 15px;
  margin-right: 1px;
  width: 100%
}

.relation-item {
  padding: 12px;

}
.dialog-footer{
  padding-top:10px ;
  padding-left: 10%;
}
//布局样式区
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>
