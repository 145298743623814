<template>
  <div>
    <el-form inline class="mb-20">
      <el-form-item label="出发地">
        <el-input placeholder="请输入出发地" v-model="form.start_place"/>
      </el-form-item>
      <el-form-item label="目的地">
        <el-input placeholder="请输入目的地" v-model="form.end_place"/>
      </el-form-item>
      <el-form-item label="最早时间">
        <el-date-picker
          v-model="form.start_time"
          type="datetime"
          placeholder="请选择最早时间"
          format="YYYY/MM/DD hh:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="最晚时间">
        <el-date-picker
          v-model="form.end_time"
          type="datetime"
          placeholder="请选择最晚时间"
          format="YYYY/MM/DD hh:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="帖子状态">
        <el-select placeholder="请选择帖子状态" v-model="form.article_status">
          <el-option label="全部" value=""/>
          <el-option label="正常" :value="10"/>
          <el-option label="被举报" :value="20"/>
          <el-option label="封禁" :value="30"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table class="mb-20" stripe border :data="list">
      <el-table-column align="center" prop="article_id" label="ID"/>
      <el-table-column align="center" prop="start_place" label="出发地"/>
      <el-table-column align="center" prop="end_place" label="目的地"/>
      <el-table-column align="center" prop="status.text" label="状态"/>
      <el-table-column align="center" prop="create_time" label="发布时间"/>
      <el-table-column align="center" prop="article_status.text" label="帖子状态"/>
      <el-table-column align="center" label="操作">
        <template #default="{row}">
          <el-button type="text" @click="showDetailBox(row)">查看详情</el-button>
          <el-divider direction="vertical"></el-divider>
          <el-popconfirm title="确定删除?" confirmButtonText="删除" cancelButtonText="取消" @confirm="del(row)">
            <template #reference>
              <el-button type="text">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="end">
      <el-col span="1">
        <el-pagination
          :page="params.page"
          :total="total"
          :current-page="params.page"
          @current-change="changePage"
          layout="prev, pager, next"
          background
        ></el-pagination>
      </el-col>
    </el-row>
    <el-dialog
      v-model="detailBox"
      title="详情"
      width="700px"
    >
      <el-descriptions border :column="1" v-if="detail">
        <el-descriptions-item label="出发地">{{detail.start_place}}</el-descriptions-item>
        <el-descriptions-item label="目的地">{{detail.end_place}}</el-descriptions-item>
        <el-descriptions-item label="出发时间">{{detail.start_time}}</el-descriptions-item>
        <el-descriptions-item label="余座">{{detail.surplus_seat}}</el-descriptions-item>
        <el-descriptions-item label="发布人">{{detail.nickName}}</el-descriptions-item>
        <el-descriptions-item label="发布人电话">{{detail.phone}}</el-descriptions-item>
        <el-descriptions-item label="发布人微信">{{detail.wechat}}</el-descriptions-item>
        <el-descriptions-item label="其他要求">{{detail.remark}}</el-descriptions-item>
        <el-descriptions-item label="发布时间">{{detail.create_time}}</el-descriptions-item>
        <el-descriptions-item label="帖子状态">{{detail.article_status.text}}</el-descriptions-item>
      </el-descriptions>
      <template #footer>
        <div>
          <el-button @click="hideDetailBox">关闭</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";

export default {
  name: 'Carpool',
  data() {
    return {
      params: {
        page: 1,
        per_page: 20,
      },
      total: 1,
      list: [],
      detailBox: false,
      detail: null,

      form: {
        start_place: '',
        end_place: '',
        start_time: '',
        end_time: '',
        article_status: ''
      },
    };
  },
  created() {
    this.getList(1)
  },
  methods: {
    showDetailBox(data) {
      // console.log(data);
      this.detail = data;
      this.detailBox = true;
    },
    hideDetailBox() {
      this.detailBox = false;
    },
    getList(page) {
      let that = this;
      that.params.load = true
      this.$api.articleCarpool.page({
        ...this.form,
        page: page || this.params.page,
      }).then((res) => {
        that.list = res.data.data;
        that.total = res.data.total;
        that.params.per_page = res.data.per_page;
        that.params.page = (page || this.params.page);
      });
    },
    del(data) {
      let that = this;
      this.$api.article.delete({
        article_id: data.article_id,
      }).then(() =>{
        ElMessage({
          message: '操作',
          type: 'success',
        })
        that.getList()
      })
    },
    submit() {
      this.getList(1)
    },
    reset() {
      this.params.search = '';
      this.submit()
    },
    changePage(page) {
      this.getList(page)
    },
  },
};
</script>
