<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="20" :offset="2">
        <el-form ref="formRef" :model="form" label-width="180px">
          <el-form-item label="课程使用教程：">
            <div>{{form.course_note_url}}</div><el-button type="text" style="margin-left: 5px" @click="edit('course_note_url')">修改</el-button>
          </el-form-item>
          <el-form-item label="关于我们：">
            <div>{{form.about_us_url}}</div><el-button type="text" style="margin-left: 5px" @click="edit('about_us_url')">修改</el-button>
          </el-form-item>
          <el-form-item label="社区规范：">
            <div>{{form.community_norm_url}}</div><el-button type="text" style="margin-left: 5px" @click="edit('community_norm_url')">修改</el-button>
          </el-form-item>
          <el-form-item label="客服微信二维码：">
            <el-image
              style="width: 100px; height: 100px"
              :src="form.kefu_qrcode_url"
              fit="cover"
            ></el-image><el-button type="text" style="margin-left: 5px" @click="edit('kefu_qrcode_url')">修改</el-button>
          </el-form-item>
          <el-form-item label="公众号链接：">
            <div>{{form.official_qrcode_url}}</div><el-button type="text" style="margin-left: 5px" @click="edit('official_qrcode_url')">修改</el-button>
<!--            <el-image-->
<!--              style="width: 100px; height: 100px"-->
<!--              :src="form.official_qrcode_url"-->
<!--              fit="cover"-->
<!--            ></el-image><el-button type="text" style="margin-left: 5px" @click="edit('official_qrcode_url')">修改</el-button>-->
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-dialog
      v-model="dialogVisible"
      title="修改信息"
      width="400px"
    >
      <el-input placeholder="请输入内容" v-model="value_flag" v-if="dialog_type == 'about_us_url' || dialog_type == 'community_norm_url' || dialog_type == 'course_note_url' || dialog_type == 'official_qrcode_url'"></el-input>
      <el-upload
        action="https://api.yjqn.club/common/upload/upload"
        list-type="picture-card"
        :limit="1"
        :on-success="success"
        v-if="dialog_type == 'kefu_qrcode_url'"
        :file-list="pic_flag"
        :on-preview="handlePictureCardPreview"
        @handleStart="exceed"
      >
        <el-icon><plus /></el-icon>
      </el-upload>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submit"
        >保存</el-button
        >
      </span>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogVisible2" width="400px">
      <img width="300" :src="dialogImageUrl2" alt="" style="margin: 0 auto;display: block"/>
    </el-dialog>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  name: 'Other',
  data() {
    return {
      form: {
        about_us_url: '',
        community_norm_url: '',
        course_note_url: '',
        kefu_qrcode_url: '',
        official_qrcode_url: '',
      },
      dialogVisible: false,
      dialog_type: '',
      value_flag: '',
      pic_flag: '',

      flag_form: null,

      dialogVisible2: false,
      dialogImageUrl2: ''
    };
  },
  created() {
    this.$api.setting.show({
      key: 'official'
    }).then(res => {
      this.form = res.data
    })
  },
  methods: {
    success(e) {
      // console.log(e);
      if(this.dialog_type == 'kefu_qrcode_url') {
        this.flag_form.kefu_qrcode_url = e.data.cos_domain + e.data.file_url
      } else if(this.dialog_type == 'official_qrcode_url') {
        this.flag_form.official_qrcode_url = e.data.cos_domain + e.data.file_url
      }
      // this.$set(this.pic_flag,0 , {
      //   name: 'name.jpg',
      //   url: e.data.cos_domain + e.data.file_url
      // })
      this.pic_flag = [{
        name: 'name.jpg',
        url: e.data.cos_domain + e.data.file_url
      }]
    },
    handlePictureCardPreview(file) {
      // console.log(file);
      this.dialogImageUrl2 = file.url;
      this.dialogVisible2 = true
    },
    exceed(files) {
      this.$set(this.pic_flag,0 , null)
    },
    edit(type) {
      this.dialog_type = type;
      this.flag_form = JSON.parse(JSON.stringify(this.form));
      if(type == 'about_us_url' || type == 'community_norm_url' || type == 'course_note_url' || type == 'official_qrcode_url') {
        this.value_flag = this.flag_form[type];
      } else if(type == 'kefu_qrcode_url') {
        this.pic_flag = [{
          name: 'name.jpg',
          url: this.flag_form[type]
        }];
      }
      this.dialogVisible = true;
    },
    submit() {
      let that = this;
      if(this.dialog_type == 'kefu_qrcode_url') {
      } else {
        this.flag_form[this.dialog_type] = this.value_flag
      }
      for(let key in this.flag_form) {
        if(this.flag_form[key] == '') {
          delete this.flag_form[key]
        }
      }
      this.$api.setting.edit({
        key: 'official',
        value: that.flag_form
      }).then(res => {
        for(let key in that.flag_form) {
          that.form[key] = that.flag_form[key]
        }
        that.dialog_type = ''
        that.dialogVisible = false
      })
    },
  },
};
</script>

<style scoped>

</style>
