<template>
  <div>
    <div class="right-box">
      <el-button type="primary" @click="showDetailBox">添加公告</el-button>
    </div>
    <el-table class="mb-20" stripe border :data="list">
      <el-table-column align="center" prop="id" label="ID"/>
      <el-table-column align="center" prop="remark" label="内容"/>
      <el-table-column align="center" prop="create_time" label="发布时间"/>
      <el-table-column align="center" label="操作">
        <template #default="{row}">
          <el-button type="text" @click="showDetailBox(row)">修改</el-button>
          <el-divider direction="vertical"></el-divider>
          <el-popconfirm title="确定删除?" confirmButtonText="删除" cancelButtonText="取消" @confirm="del(row)">
            <template #reference>
              <el-button type="text">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="end">
      <el-col span="1">
        <el-pagination
          :page="params.page"
          :total="total"
          :current-page="params.page"
          @current-change="changePage"
          layout="prev, pager, next"
          background
        ></el-pagination>
      </el-col>
    </el-row>
    <el-dialog
      v-model="detailBox"
      :title="form.id ? '修改公告' : '添加公告'"
      width="500px"
    >
      <el-form label-width="80px">
        <el-form-item label="公告内容">
          <el-input placeholder="请输入公告内容" v-model="form.content"/>
        </el-form-item>
      </el-form>
      <template #footer>
        <div>
          <el-button @click="submit">保存</el-button>
          <el-button @click="hideDetailBox">关闭</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";

export default {
  name: 'IdleCategory',
  data() {
    return {
      params: {
        page: 1,
        per_page: 20,
      },
      total: 1,
      list: [],
      detailBox: false,
      form: {
        content: '',
      }
    };
  },
  created() {
    this.getList(1)
  },
  methods: {
    showDetailBox(data) {
      if(data) {
        this.form.id = data.id
        this.form.content = data.remark
      }
      this.detailBox = true;
    },
    hideDetailBox() {
      delete this.form.id;
      this.detailBox = false;
    },
    getList(page) {
      let that = this;
      that.params.load = true
      this.$api.accusationArticle.noticePage({
        page: page || this.params.page,
      }).then((res) => {
        // console.log(res);
        that.list = res.data.data;
        that.total = res.data.total;
        that.params.per_page = res.data.per_page;
        that.params.page = (page || this.params.page);
      });
    },
    changePage(page) {
      this.getList(page)
    },
    del(data) {
      let that = this;
      this.$api.accusationArticle.delete({
        id: data.id,
      }).then(() =>{
        ElMessage({
          message: '操作',
          type: 'success',
        })
        that.getList()
      })
    },
    submit() {
      let form = this.form;
      let that = this;
      if(form.id) {
        this.$api.accusationArticle.edit({
          ...form,
        }).then(res => {
          ElMessage({
            message: '保存成功',
            type: 'success',
          })
          that.getList()
          that.hideDetailBox()
        })
      } else {
        this.$api.accusationArticle.notice({
          ...form,
        }).then(res => {
          ElMessage({
            message: '保存成功',
            type: 'success',
          })
          that.getList()
          that.hideDetailBox()
        })
      }
    },
  },
};
</script>

<style scoped>

</style>
